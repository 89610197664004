import React from 'react';
import { SEO } from '../components/SEO';
import styled from '../styled';
import { ICategory, IArticleDetail } from '../services/helpscout';
import { HelpCenter } from '../layouts/HelpCenter';
import { Article } from '../components/Article';
import { graphql } from 'gatsby';

type PageQueryData = {
  helpScoutArticle: IArticleDetail;
  articles: IArticleDetail[];
  categories: ICategory[];
};

const ArticleFooter = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(p) => p.theme.palette.grey[500]};
  margin-top: 100px;

  a {
    color: ${(p) => p.theme.palette.primary.main};
    border-bottom: 1px solid;
  }
`;

const Content = ({
  article,
  articles
}: {
  article: IArticleDetail;
  articles: IArticleDetail[];
}) => {
  const lastUpdated = new Date(article.lastPublishedAt).toLocaleDateString(
    'en-US'
  );

  return (
    <>
      <h1>{article.name}</h1>
      <Article dangerouslySetInnerHTML={{ __html: article.text }} />
      <ArticleFooter>
        <p>
          Have questions? Contact us at{' '}
          <a href="mailto:support@affilimate.com">support@affilimate.com</a>{' '}
          anytime.
        </p>
        <p>Last Updated {lastUpdated}</p>
      </ArticleFooter>
    </>
  );
};

export const pageQuery = graphql`
  query($id: String) {
    helpScoutArticle(id: { eq: $id }) {
      categories
      collectionId
      createdAt
      createdBy
      hasDraft
      id
      articleId
      keywords
      lastPublishedAt
      name
      number
      popularity
      publicUrl
      slug
      status
      text
      updatedAt
      updatedBy
      viewCount
    }
  }
`;

export default function Template({
  data,
  pageContext
}: {
  data: PageQueryData;
  pageContext: { categories: ICategory[]; articles: IArticleDetail[] };
}) {
  const { helpScoutArticle: article } = data;
  const categories = pageContext.categories;
  const articles = pageContext.articles;

  return (
    <>
      <SEO
        title={article.name}
        pathname={`/docs/${article.slug}/`}
        publishedDate={article.lastPublishedAt}
        description="Support, tutorials, and best practices for affiliate publishers using Affilimate to grow their revenue."
        image="/images/help-center.png"
      />
      <HelpCenter
        content={<Content articles={articles} article={article} />}
        currentArticle={article}
        articles={articles}
        categories={categories}
      />
    </>
  );
}
